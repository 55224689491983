<template>
  <div>
    <v-dialog persistent v-model="$store.state.dialog" width="800">
      <v-card flat class="card">
        <v-toolbar outlined flat color="primary lighten-2">
          <v-toolbar-title>
            {{
              $store.state.itemDetails.dialogType == "add" ? "إضافة" : "تعديل"
            }}
            {{ this.$route.meta.single }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn :disabled="$global.state.loading" @click="close()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form @submit.prevent="submit()" ref="form" lazy-validation>
          <v-container>
            <v-card outlined>
              <v-container>
                <v-row>
                  <!-- <v-col cols="12" md="6">
                                        <h4 class="mb-2">
                                            العنوان
                                            <span class="required">*</span>
                                        </h4>
                                        <v-text-field
                                            v-model="item.title"
                                            placeholder="العنوان"
                                            :rules="[$global.state.required()]"
                                            required
                                            filled
                                            outlined
                                            hide-details="auto"
                                        />
                                    </v-col> -->

                  <!-- description -->
                  <v-col cols="12" md="12">
                    <h4 class="mb-2">الوصف</h4>
                    <v-textarea
                      v-model="item.caption"
                      required
                      outlined
                      filled
                      hide-details="auto"
                      label="لا توجد ملاحضات"
                      color="secondary"
                    ></v-textarea>
                  </v-col>

                  <!-- attachments -->
                  <v-col
                    cols="12"
                    md="12"
                    v-if="$store.state.itemDetails.dialogType == 'add'"
                  >
                    <h4 class="mb-2">صور للمادة</h4>
                    <MultipartFileUpload
                      accept="image/*;capture=camera"
                      formData="files"
                      endPoint="UploadFile/postUploadFiles"
                      buttonText="رفع صـور للمادة"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <v-col cols="12" md="12">
              <v-row class="mt-2">
                <v-btn
                  type="submit"
                  :loading="$global.state.loading"
                  x-large
                  color="primary"
                  class="btn"
                  :disabled="!item.caption"
                  >حفظ</v-btn
                >
                <v-btn
                  @click="close()"
                  x-large
                  :loading="$global.state.loading"
                  color="error"
                  class="mr-2 btn"
                  outlined
                  >إلغاء</v-btn
                >
              </v-row>
            </v-col>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MultipartFileUpload from "@/components/MultipartFileUpload.vue";

export default {
  components: {
    MultipartFileUpload,
  },

  data() {
    return {
      item: {},
    };
  },

  mounted() {
    this.reset();
    this.$eventBus.$on("fill-fields", () => {
      this.item = this.$store.state.itemDetails;
    });
  },

  methods: {
    reset() {
      this.item = {
        caption: "",
        files: [],
      };
      this.$store.commit("clearFiles");
    },

    submit() {
      if (this.$store.state.itemDetails.dialogType == "add") {
        this.addItem();
      } else {
        this.editItem();
      }
    },

    async addItem() {
      this.$global.state.loading = true;
      let formData = new FormData();
      if (this.$store.state.files[0] != null) {
        this.$store.state.progress = true;
        formData.append("Caption", this.item.caption);
        for (const i of Object.keys(this.$store.state.files)) {
          console.log(this.$store.state.files[i]);
          formData.append("Files", this.$store.state.files[i]);
        }
        /*  await this.$http
                    .post("/UploadFile/postUploadFiles", formData)
                    .then(res => {
                        this.item.attachments = res.data.result.fileList;
                    });*/
      }
      await this.$http
        .post(`${this.$route.meta.endPoint}`, formData, {
          "Content-Type": "multipart/form-data",
        })
        .then((res) => {
          this.$eventBus.$emit(`refresh`);
          this.close();
          this.$store.dispatch("toastification/setToast", {
            message: `${res.data.message}`,
            type: "success",
          });
        })
        .catch((err) => {
          let msg = this.getStringErrors(err.response.data);
          // console.log(msg);
          this.$store.dispatch("toastification/setToast", {
            message: msg,
            type: "error",
          });
        })
        .finally(() => (this.$global.state.loading = false));
    },

    async editItem() {
      this.$global.state.loading = true;
      //if (this.$store.state.files[0] != null) {
      //this.$store.state.progress = true;
      //     let formData = new FormData();
      //     for (const i of Object.keys(this.$store.state.files)) {
      //       formData.append("files", this.$store.state.files[i]);
      //     }
      //     await this.$http.post("/UploadFile/postUploadFiles", formData).then((res) => {
      //       this.item.attachments = res.data.result.fileList;
      //       // res.data.result.fileList.forEach(element => {
      //       //     this.item.attachments.push({
      //       //         path: element.path,
      //       //         name: element.name,
      //       //         type: element.type,
      //       //         isMain: true
      //       //     });
      //       // });
      //     });
      //  }
      let request = {
        caption: this.item.caption,
      };
      await this.$http
        .put(`${this.$route.meta.endPoint}/${this.item.id}`, request)
        .then((res) => {
          this.close();
          this.$store.dispatch("toastification/setToast", {
            message: `${res.data.message}`,
            type: "success",
          });
          this.$eventBus.$emit(`refresh`);
        })
        .catch((err) => {
          this.$store.dispatch("toastification/setToast", {
            message: `${err.data.message}`,
            type: "error",
          });
        })
        .finally(() => (this.$global.state.loading = false));
    },

    close() {
      this.$store.commit("setDialog");
      this.reset();
      this.resetValidation();
      this.$store.commit("setItemDetails", {});
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getStringErrors(obj) {
      // console.log(obj,typeof(obj));
      let result = "";

      // Check if the object has a message of type string
      if (obj.message && typeof obj.message === "string") {
        result += `${obj.message}\n`;
      }

      // Check if the object has an inner object named errors
      if (obj.errors && typeof obj.errors === "object") {
        console.log("in");
        // Concatenate key-value pairs from the errors object
        for (const key in obj.errors) {
          if (Object.prototype.hasOwnProperty.call(obj.errors, key)) {
            // Ensure that the value is a string before appending to the result
            if (typeof obj.errors[key] === "string") {
              result += `${key}: ${obj.errors[key]}\n`;
            }
          }
        }
      }

      return result.trim(); // Trim to remove trailing newline
    },
  },
};
</script>

<style scoped>
.btn-delete-file {
  padding: 11px;
  font-weight: bold;
}
</style>
